import '../handlers';
import '../css/general.css';
import '../components/Button';
import '../components/DropDown';
import '../components/Input';
import '../components/Spiner';
import '../components/Tag';
import '../components/Card';
import '../components/Collapse';
import '../components/Collapse/CollapseItem';
import '../components/InputNumber';
import '../components/Modal';
import '../components/Menu';
import '../components/Menu/MenuItem';
import '../components/Template';
import '@fortawesome/fontawesome-free/js/all';
